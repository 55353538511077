import Header from "../components/header"
import RosterList from "../components/rosterList"
import {InformationCircleIcon, UserPlusIcon} from '@heroicons/react/20/solid'

function Rosters() {


    return(
        <>
            <Header text={"Rosters"}/>
            <div className="flex gap-x-3 info-margin" >
                <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                Rosters for the 2025 spring session.
                </span>
            </div>
            <RosterList/>
        </>
    )
}

export default Rosters;