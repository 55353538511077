
//season 1
// const rosters = [
//     {
//         id: 1,
//         name: 'Team 1',
//         captain: "Elliot McLeod",
//         coCaptain: "Lachlan Ross",
//         players: [
//             "Nick Bryant",
//             "Rheid Cline",
//             "Austin Diamond",
//             "Sean Eriksen",
//             "Chris Fayant",
//             "Daymond Harshman",
//             "Chris Hastings",
//             "Casey Jordan",
//             "Patrick Jordan",
//             "Cody Kimerly",
//             "Jared Patel",
//             "Justin Pinkerton",
//             "Greg Wilder",
//             "Cy Moody - Goalie"
//         ],
//
//     },
//     {
//         id: 2,
//         name: 'Team 2',
//         captain: "Jake Flynn",
//         coCaptain: "Max Flynn",
//         players: [
//             "Jake Browning",
//             "Luke Browning",
//             "Eric Christensen",
//             "Jacob Christensen",
//             "Austin Cockrum",
//             "Dan Flynn",
//             "Rocky Flynn",
//             "Ty Flynn",
//             "Dalton Graham",
//             "Aaron Jackson",
//             "Bryon McDougal",
//             "Jake Peterson",
//             "Noel Mathison - Goalie"
//         ],
//     },
//     {
//         id: 3,
//         name: 'Team 3',
//         captain: "Logan Helgeland",
//         coCaptain: "Brandon Burrell",
//         players: [
//             "Cory Bartheld",
//             "Hunter Brown",
//             "Roberto Dominguez",
//             "Drake Flynn",
//             "Zach Frasier",
//             "Denton Hymer",
//             "Eddie Kavanaugh",
//             "Billy Martin",
//             "Chris Mikkelsen",
//             "Westin Montgomery",
//             "Monte Montoya",
//             "Gary Roy",
//             "Austin Scott",
//             "James Kirkham - Goalie"
//         ],
//     },
//     {
//         id: 4,
//         name: 'Team 4',
//         captain: "Andrew Deskin",
//         coCaptain: "Quin Gist",
//         players: [
//             "Ben Albertson",
//             "Alan Carreon",
//             "Jeromy Deskin",
//             "Jake Hagan",
//             "Derek Lee",
//             "Gary Mullen",
//             "Ross Mullen",
//             "Adam Munson",
//             "Mitch Reiman",
//             "Mike Sabatello",
//             "Cody Sadler",
//             "Teka Sellers",
//             "Christian Thorn",
//             "Doug Kuo - Goalie"
//         ],
//     },
// ]

//season 2
// const rosters = [
//     {
//         id: 1,
//         name: 'Team 1',
//         captain: "Max Flynn",
//         coCaptain: "Quin Gist",
//         players: [
//             "Cody Bartheld",
//             "Alan Carreon",
//             "Austin Diamond",
//             "Sean Eriksen",
//             "Dan Flynn",
//             "Drake Flynn",
//             "Ty Flynn",
//             "Cody Kimmerly",
//             "Cameron LaRue",
//             "Monte Montoya",
//             "Jared Patel",
//             "Justin Pinkerton",
//             "Cody Sadler",
//             "Landen Kimmerly - Goalie"
//         ],
//
//     },
//     {
//         id: 2,
//         name: 'Team 2',
//         captain: "Andrew Deskin",
//         coCaptain: "Elliot McLeod",
//         players: [
//             "Ben Albertson",
//             "Seth Barnes",
//             "Eric Christensen",
//             "Jacob Christensen",
//             "Jeromy Deskin",
//             "Damond Harshman",
//             "Billy Martin",
//             "Gary Mullen",
//             "Ross Mullen",
//             "Adam Munson",
//             "Jake Peterson",
//             "Trenton Spears",
//             "Christian Thorn",
//             "Doug Kuo - Goalie"
//         ],
//     },
//     {
//         id: 3,
//         name: 'Team 3',
//         captain: "Brandon Burrell",
//         coCaptain: "Lachlan Ross",
//         players: [
//             "Luke Browning",
//             "Nick Bryant",
//             "Chris Fayant",
//             "Casey Jordan",
//             "Patrick Jordan",
//             "Bryon McDougal",
//             "Chris Mikkelsen",
//             "Blake Reiman",
//             "Mitch Reiman",
//             "Wesley Rix",
//             "Gary Roy",
//             "Ian Whited",
//             "Greg Wilder",
//             "Noel Mathison - Goalie"
//         ],
//     },
//     {
//         id: 4,
//         name: 'Team 4',
//         captain: "Jake Flynn",
//         coCaptain: "Logan Helgeland",
//         players: [
//             "Alex Bogdan",
//             "Jared Bogdan",
//             "Rheid Cline",
//             "Ben Cope",
//             "Roberto Dominguez",
//             "Rocky Flynn",
//             "Zach Frasier",
//             "Dustin Huber",
//             "Derek Lee",
//             "Westin Montgomery",
//             "Cy Moody",
//             "Mike Sabatello",
//             "Austin Scott",
//             "James Kirkham - Goalie"
//         ],
//     },
// ]


const rosters = [
    {
        id: 1,
        name: 'Team 1',
        captain: "Quinn Gist",
        coCaptain: "Ben Albertson",
        players: [
            "Nick Bryant",
            "Alan Carreon",
            "Eric Christensen",
            "Jacob Christensen",
            "Rheid Cline",
            "Austin Diamond",
            "Chris Fayant",
            "Dan Flynn",
            "Zach Fraiser",
            "Ian Isby",
            "Eric Life",
            "Cody Sadler",
            "Max Flynn (Goalie)"
        ],

    },
    {
        id: 2,
        name: 'Team 2',
        captain: "Jake Flynn",
        coCaptain: "Logan Helgeland",
        players: [
            "Cory Bartheld",
            "Adam Brastad",
            "Riley Brucell",
            "Rocky Flynn",
            "Dalton Graham",
            "Dustin Huber",
            "Eddie Kavanaugh",
            "Billy Martin",
            "Bryon McDougall",
            "Elliot McCleod",
            "Adam Munson",
            "Trenton Spears",
            "Jason Munson (Goalie)"
        ],
    },
    {
        id: 3,
        name: 'Team 3',
        captain: "Andrew Deskin",
        coCaptain: "Seth Barnes",
        players: [
            "Jacob Browning",
            "Luke Browning",
            "Jeromy Deskin",
            "Logan Finley",
            "Maya Garetson",
            "Damond Harshman",
            "Pat Jordan",
            "Cody Kimmerly",
            "Mike Sabatello",
            "Teka Sellers",
            "Christian Thorn",
            "Daniel Tuffarelli",
            "James Kirkham (Goalie)"
        ],
    },
    {
        id: 4,
        name: 'Team 4',
        captain: "Lachlan Ross",
        coCaptain: "Brandon Burrell",
        players: [
            "Alex Bogdan",
            "Jared Bogdan",
            "Ben Cope",
            "Brad Couts",
            "Derek Lee",
            "Chris Mikkelsen",
            "Monte Montoya",
            "Gary Mullen",
            "Ross Mullen",
            "Justin Pinkerton",
            "Wesley Rix",
            "Ethan Smith",
            "Greg Wilder",
            "Doug/Cy (Goalie)"
        ],
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RosterList() {
    return (
        <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 mb-8 lg:grid-cols-3 xl:gap-x-8 side-margins">
            {rosters.map((roster) => (
                <li key={roster.id} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">

                        <div className="text-sm font-medium leading-6 text-gray-900">{roster.name}</div>

                    </div>

                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">{roster.captain}</dt>
                            <dd className="text-gray-700">
                                Captain
                            </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">{roster.coCaptain}</dt>
                            <dd className="text-gray-700">
                                Co-Captain
                            </dd>
                        </div>

                        {roster.players.map((player, index) => (
                            <div className="flex justify-between gap-x-4 py-3" key={`${roster.id}-${player}-${index}`}>
                                <dt className="text-gray-500">{player}</dt>
                                <dd className="text-gray-700">

                                </dd>
                            </div>
                        ))}

                    </dl>

                </li>
            ))}
        </ul>
    )
}