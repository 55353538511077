
//season 1
// const teams = [
//     { teamName: 'Team 1', gamesPlayed: 12, wins: 2, losses:7, otLoss: 3, record: 7, goals: 44, assists:49, points:93, pims: 39 },
//     { teamName: 'Team 2', gamesPlayed: 12, wins: 6, losses:6, otLoss: 0, record: 12, goals: 55, assists:84, points:139, pims: 33 },
//     { teamName: 'Team 3', gamesPlayed: 12, wins: 9, losses:3, otLoss: 0, record: 18, goals: 61, assists:79, points:140, pims: 24 },
//     { teamName: 'Team 4', gamesPlayed: 12, wins: 7, losses:4, otLoss: 1, record: 15, goals: 51, assists:64, points:115, pims: 27  },
// ];

//season 2
// const teams = [
//     { teamName: 'Team 1', gamesPlayed: 11, wins: 8, losses: 3, otLoss: 0, record: 16, goals: 71, assists: 84, points: 155, pims: 9 },
//     { teamName: 'Team 2', gamesPlayed: 11, wins: 6, losses: 4, otLoss: 1, record: 13, goals: 71, assists: 84, points: 155, pims: 61 },
//     { teamName: 'Team 3', gamesPlayed: 11, wins: 5, losses: 6, otLoss: 0, record: 10, goals: 58, assists: 79, points: 137, pims: 33 },
//     { teamName: 'Team 4', gamesPlayed: 11, wins: 3, losses: 8, otLoss: 0, record: 6, goals: 52, assists: 77, points: 129, pims: 33 },
// ]


//season 3
// const teams = [
//     { teamName: 'Team 1', gamesPlayed: 11, wins: 7, losses: 3, otLoss: 1, record: 15, goals: 66, assists: 78, points: 144, pims: 12 },
//     { teamName: 'Team 2', gamesPlayed: 11, wins: 7, losses: 4, otLoss: 0, record: 14, goals: 62, assists: 87, points: 149, pims: 6 },
//     { teamName: 'Team 3', gamesPlayed: 11, wins: 2, losses: 9, otLoss: 0, record: 4, goals: 38, assists: 47, points: 85, pims: 27 },
//     { teamName: 'Team 4', gamesPlayed: 11, wins: 7, losses: 4, otLoss: 0, record: 14, goals: 54, assists: 71, points: 125, pims: 12 },
// ]


//season 4
const teams = [
    { teamName: 'Team 1', gamesPlayed: 2, wins: 2, losses: 0, otLoss: 0, record: 4, goals: 16, assists: 13, points: 29, pims: 0 },
    { teamName: 'Team 2', gamesPlayed: 2, wins: 1, losses: 1, otLoss: 0, record: 2, goals: 6, assists: 9, points: 15, pims: 0 },
    { teamName: 'Team 3', gamesPlayed: 2, wins: 0, losses: 2, otLoss: 0, record: 0, goals: 5, assists: 4, points: 9, pims: 0 },
    { teamName: 'Team 4', gamesPlayed: 2, wins: 1, losses: 1, otLoss: 0, record: 2, goals: 8, assists: 12, points: 20, pims: 0 },
]

export default function TeamsStatTable() {
    return (
        <div className="px-4 sm:px-6 lg:px-8 mb-24 side-margins">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Team Stats</h1>
                </div>

            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Team Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Games Played
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Wins
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Losses
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    OT Losses
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Standing
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Goals
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Assists
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Player Points
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Pims
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {teams.map((team, index) => (
                                <tr key={`${team.teamName}-${index}`}>
                                    <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-0">
                                        {team.teamName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.gamesPlayed}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.wins}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.losses}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.otLoss}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.record}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.goals}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.assists}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.points}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{team.pims}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}