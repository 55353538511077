export const season1 = [

    //session 1
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2023-09-18T19:45:00'),
        end: new Date('2023-09-18T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2023-09-19T20:00:00'),
        end: new Date('2023-09-19T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2023-09-25T19:45:00'),
        end: new Date('2023-09-25T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2023-09-26T20:00:00'),
        end: new Date('2023-09-26T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2023-10-02T19:45:00'),
        end: new Date('2023-10-02T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2023-10-03T20:00:00'),
        end: new Date('2023-10-03T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2023-10-09T19:45:00'),
        end: new Date('2023-10-09T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2023-10-10T20:00:00'),
        end: new Date('2023-10-10T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2023-10-16T19:45:00'),
        end: new Date('2023-10-16T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2023-10-17T20:00:00'),
        end: new Date('2023-10-17T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 2',
        start: new Date('2023-10-23T19:45:00'),
        end: new Date('2023-10-23T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2023-10-24T20:00:00'),
        end: new Date('2023-10-24T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2023-10-30T19:45:00'),
        end: new Date('2023-10-30T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2023-10-30T21:15:00'),
        end: new Date('2023-10-30T22:30:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 2',
        start: new Date('2023-11-06T19:45:00'),
        end: new Date('2023-11-06T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2023-11-07T20:00:00'),
        end: new Date('2023-11-07T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2023-11-13T19:45:00'),
        end: new Date('2023-11-13T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2023-11-13T21:15:00'),
        end: new Date('2023-11-13T22:30:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 2',
        start: new Date('2023-11-20T19:45:00'),
        end: new Date('2023-11-20T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2023-11-21T20:00:00'),
        end: new Date('2023-11-21T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2023-11-27T19:45:00'),
        end: new Date('2023-11-27T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2023-11-27T21:15:00'),
        end: new Date('2023-11-27T22:30:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2023-12-04T19:45:00'),
        end: new Date('2023-12-04T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2023-12-05T20:00:00'),
        end: new Date('2023-12-05T21:15:00'),
        extraInfo: "blah blah blah"
    },

    // playoffs
    {
        title: '2nd place plays 3rd place',
        start: new Date('2023-12-11T19:45:00'),
        end: new Date('2023-12-11T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: '1st place plays 4th place',
        start: new Date('2023-12-12T20:00:00'),
        end: new Date('2023-12-12T21:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'final game, winners of semi finals',
        start: new Date('2023-12-18T20:45:00'),
        end: new Date('2023-12-18T22:00:00'),
    },


    //session 2
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-01-16T20:00:00'),
        end: new Date('2024-01-16T21:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-01-16T21:30:00'),
        end: new Date('2024-01-16T22:45:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-01-22T19:45:00'),
        end: new Date('2024-01-22T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-01-23T20:00:00'),
        end: new Date('2024-01-23T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-01-29T19:45:00'),
        end: new Date('2024-01-29T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-01-30T20:00:00'),
        end: new Date('2024-01-30T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-02-05T19:45:00'),
        end: new Date('2024-02-05T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-02-06T20:00:00'),
        end: new Date('2024-02-06T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-02-12T19:45:00'),
        end: new Date('2024-02-12T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-02-13T20:00:00'),
        end: new Date('2024-02-13T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-02-19T19:45:00'),
        end: new Date('2024-02-19T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-02-20T20:00:00'),
        end: new Date('2024-02-20T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-02-26T19:45:00'),
        end: new Date('2024-02-26T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-02-27T20:00:00'),
        end: new Date('2024-02-27T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-03-05T20:00:00'),
        end: new Date('2024-03-05T21:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-03-05T21:30:00'),
        end: new Date('2024-03-05T22:45:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-03-12T20:00:00'),
        end: new Date('2024-03-12T21:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-03-12T21:30:00'),
        end: new Date('2024-03-12T22:45:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-03-18T19:45:00'),
        end: new Date('2024-03-18T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-03-19T20:00:00'),
        end: new Date('2024-03-19T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-03-25T19:45:00'),
        end: new Date('2024-03-25T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-03-26T20:00:00'),
        end: new Date('2024-03-26T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-04-01T19:00:00'),
        end: new Date('2024-04-01T20:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-04-02T19:00:00'),
        end: new Date('2024-04-02T20:15:00'),
        extraInfo: "blah blah blah"
    },

    // playoffs
    {
        title: '2nd place plays 3rd place',
        start: new Date('2024-04-08T19:00:00'),
        end: new Date('2024-04-08T20:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: '1st place plays 4th place',
        start: new Date('2024-04-09T19:00:00'),
        end: new Date('2024-04-09T20:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'final game, winners of semi finals',
        start: new Date('2024-04-15T19:00:00'),
        end: new Date('2024-04-15T20:15:00'),
    },











    //session 3
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-09-09T19:45:00'),
        end: new Date('2024-09-09T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-09-11T20:00:00'),
        end: new Date('2024-09-11T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-09-16T19:45:00'),
        end: new Date('2024-09-16T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-09-18T20:00:00'),
        end: new Date('2024-09-18T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-09-23T19:45:00'),
        end: new Date('2024-09-23T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-09-25T20:00:00'),
        end: new Date('2024-09-25T21:15:00'),
        extraInfo: "blah blah blah"
    },


    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-09-30T19:45:00'),
        end: new Date('2024-09-30T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-10-02T20:00:00'),
        end: new Date('2024-10-02T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-10-07T19:45:00'),
        end: new Date('2024-10-07T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-10-08T20:00:00'),
        end: new Date('2024-10-08T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-10-14T19:45:00'),
        end: new Date('2024-10-14T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-10-15T20:00:00'),
        end: new Date('2024-10-15T21:15:00'),
        extraInfo: "blah blah blah"
    },


    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-10-21T19:45:00'),
        end: new Date('2024-10-21T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-10-22T20:00:00'),
        end: new Date('2024-10-22T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-10-28T19:45:00'),
        end: new Date('2024-10-28T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-10-29T20:00:00'),
        end: new Date('2024-10-29T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-11-04T20:00:00'),
        end: new Date('2024-11-04T21:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-11-05T20:00:00'),
        end: new Date('2024-11-05T21:15:00'),
        extraInfo: "blah blah blah"
    },


    {
        title: 'Team 1 vs Team 2',
        start: new Date('2024-11-11T19:45:00'),
        end: new Date('2024-11-11T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2024-11-12T20:00:00'),
        end: new Date('2024-11-12T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2024-11-18T19:45:00'),
        end: new Date('2024-11-18T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2024-11-19T20:00:00'),
        end: new Date('2024-11-19T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 4',
        start: new Date('2024-11-25T19:45:00'),
        end: new Date('2024-11-25T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2024-11-26T20:00:00'),
        end: new Date('2024-11-26T20:15:00'),
        extraInfo: "blah blah blah"
    },


    // playoffs
    {
        title: '2nd place plays 3rd place',
        start: new Date('2024-12-02T19:45:00'),
        end: new Date('2024-12-02T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: '1st place plays 4th place',
        start: new Date('2024-12-03T20:00:00'),
        end: new Date('2024-12-03T20:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'final game, winners of semi finals',
        start: new Date('2024-12-09T19:45:00'),
        end: new Date('2024-12-09T21:00:00'),
    },










    //session 3
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2025-01-13T19:45:00'),
        end: new Date('2025-01-13T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 3 vs Team 4',
        start: new Date('2025-01-14T20:00:00'),
        end: new Date('2025-01-14T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 3',
        start: new Date('2025-01-20T19:45:00'),
        end: new Date('2025-01-20T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 4',
        start: new Date('2025-01-21T20:00:00'),
        end: new Date('2025-01-21T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2025-01-27T19:45:00'),
        end: new Date('2025-01-27T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2025-01-28T20:00:00'),
        end: new Date('2025-01-28T21:15:00'),
        extraInfo: "blah blah blah"
    },


    {
        title: 'Team 1 vs Team 3',
        start: new Date('2025-02-03T19:45:00'),
        end: new Date('2025-02-03T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 4',
        start: new Date('2025-02-04T20:00:00'),
        end: new Date('2025-02-04T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 3 vs Team 4',
        start: new Date('2025-02-10T19:45:00'),
        end: new Date('2025-02-10T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2025-02-11T20:00:00'),
        end: new Date('2025-02-11T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2025-02-17T19:45:00'),
        end: new Date('2025-02-17T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2025-02-18T20:00:00'),
        end: new Date('2025-02-18T21:15:00'),
        extraInfo: "blah blah blah"
    },


    {
        title: 'Team 2 vs Team 4',
        start: new Date('2025-02-24T19:45:00'),
        end: new Date('2025-02-24T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2025-02-25T20:00:00'),
        end: new Date('2025-02-25T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 1 vs Team 4',
        start: new Date('2025-03-03T19:45:00'),
        end: new Date('2025-03-03T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 2 vs Team 3',
        start: new Date('2025-03-04T20:00:00'),
        end: new Date('2025-03-04T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 3 vs Team 4',
        start: new Date('2025-03-10T20:00:00'),
        end: new Date('2025-03-10T21:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2025-03-11T20:00:00'),
        end: new Date('2025-03-11T21:15:00'),
        extraInfo: "blah blah blah"
    },


    {
        title: 'Team 2 vs Team 4',
        start: new Date('2025-03-17T19:45:00'),
        end: new Date('2025-03-17T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 3',
        start: new Date('2025-03-18T20:00:00'),
        end: new Date('2025-03-18T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 2 vs Team 3',
        start: new Date('2025-03-24T19:45:00'),
        end: new Date('2025-03-24T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 4',
        start: new Date('2025-03-25T20:00:00'),
        end: new Date('2025-03-25T21:15:00'),
        extraInfo: "blah blah blah"
    },

    {
        title: 'Team 3 vs Team 4',
        start: new Date('2025-03-31T19:45:00'),
        end: new Date('2025-03-31T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'Team 1 vs Team 2',
        start: new Date('2025-04-01T20:00:00'),
        end: new Date('2025-04-01T20:15:00'),
        extraInfo: "blah blah blah"
    },


    // playoffs
    {
        title: '1st place plays 4th place',
        start: new Date('2025-04-07T19:45:00'),
        end: new Date('2025-04-07T21:00:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: '2nd place plays 3rd place',
        start: new Date('2025-04-08T20:00:00'),
        end: new Date('2025-04-08T20:15:00'),
        extraInfo: "blah blah blah"
    },
    {
        title: 'final game, winners of semi finals',
        start: new Date('2025-04-14T19:45:00'),
        end: new Date('2025-04-14T21:00:00'),
    }

];

